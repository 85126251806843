import React, { useState, useEffect, useCallback } from "react";
import { Grid2, Box, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { toast } from "react-toastify";
import hostname from "../../hostname";
import Navbar from "../../components/main/navbar";
import Footer from "../../components/main/footer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [numberOfUser, setNumberOfUser] = useState(null);
  const [numberOfUtilisation, setNumberOfUtilisation] = useState(null);
  const [utilisationPercentage, setUtilisationPercentage] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [leftUser, setLeftUser] = useState(null);
  const [gainUserByDay, setGainUserByDay] = useState(null);
  const [leftByDay, setLeftByDay] = useState(null);
  const [utilisationByDay, setUtilisationByDay] = useState(null);
  const [userConversion, setUserConversion] = useState(null);
  const [userConversionByPlan, setUserConversionByPlan] = useState(null);

  const redirectToLogin = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  const handleRefreshToken = useCallback(async () => {
    try {
      const response = await fetch(`${hostname}/api/v1/admin/auth/refresh`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.ok) {
        const { accessToken } = await response.json();
        localStorage.setItem("accessToken", accessToken);
        return accessToken;
      } else {
        redirectToLogin();
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      redirectToLogin();
    }
  }, []);

  const fetchData = useCallback(
    async (url, setState) => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
          redirectToLogin();
          return;
        }

        let response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 401 || response.status === 403) {
          const newAccessToken = await handleRefreshToken();
          if (newAccessToken) {
            response = await fetch(url, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${newAccessToken}`,
              },
            });
          } else {
            throw new Error("Failed to refresh token");
          }
        }

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.message);
        }

        const data = await response.json();
        setState(data);
      } catch (error) {
        console.error("Error while fetching data:", error);
        toast.error("Error while fetching data.");
      }
    },
    [handleRefreshToken]
  );

  useEffect(() => {
    fetchData(`${hostname}/api/v1/admin/kpi/numberOfUser`, setNumberOfUser);
    fetchData(
      `${hostname}/api/v1/admin/kpi/numberOfUtilisation`,
      setNumberOfUtilisation
    );
    fetchData(
      `${hostname}/api/v1/admin/kpi/utilisationPercentage`,
      setUtilisationPercentage
    );
    fetchData(`${hostname}/api/v1/admin/kpi/getNewUser`, setNewUser);
    fetchData(`${hostname}/api/v1/admin/kpi/getLeftUser`, setLeftUser);
    fetchData(`${hostname}/api/v1/admin/kpi/gainUserByDay`, setGainUserByDay);
    fetchData(`${hostname}/api/v1/admin/kpi/LeftByDay`, setLeftByDay);
    fetchData(
      `${hostname}/api/v1/admin/kpi/utilisationByDay`,
      setUtilisationByDay
    );
    fetchData(`${hostname}/api/v1/admin/kpi/userConversion`, setUserConversion);
    fetchData(
      `${hostname}/api/v1/admin/kpi/userConversionByPlan`,
      setUserConversionByPlan
    );
  }, [fetchData]);

  const usersByDayOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Gain User By Day",
      },
    },
  };

  const LeftByDayOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Left User By Day",
      },
    },
  };

  const utilisationByDayOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Utilisation By Day",
      },
    },
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Les mois sont indexés à partir de 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const chartData = (data) => {
    // Filtrer les éléments avec _id non nul et convertir les chaînes de caractères de date en objets Date
    const filteredData = data
      .filter((item) => item._id !== null)
      .map((item) => ({
        ...item,
        _id: new Date(item._id),
      }));

    // Trier les données par date
    filteredData.sort((a, b) => a._id - b._id);

    return {
      labels: filteredData.map((item) => formatDate(item._id)), // Utiliser la fonction de formatage
      datasets: [
        {
          label: "Count",
          data: filteredData.map((item) => item.count),
          backgroundColor: "#5271ff",
        },
      ],
    };
  };

  return (
    <>
      <Navbar />
      <Box padding="35px" gap={3}>
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography
            variant="body2"
            fontWeight="700"
            fontSize="30px"
            marginTop="20px"
          >
            Dashboard
          </Typography>
        </Grid2>
        <Grid2
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          marginTop="20px"
          marginBottom="50px"
        >
          {numberOfUser && (
            <Grid2 item>
              <Typography variant="body2">
                Users: {numberOfUser.totalUsers}
              </Typography>
            </Grid2>
          )}
          {numberOfUtilisation && (
            <Grid2 item>
              <Typography variant="body2">
                Utilisations: {numberOfUtilisation.totalUtilisations}
              </Typography>
            </Grid2>
          )}
          {utilisationPercentage && (
            <Grid2 item>
              <Typography variant="body2">
                Utilisation Percentage: {utilisationPercentage.percentage}%
              </Typography>
            </Grid2>
          )}
        </Grid2>

        <Grid2
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          marginBottom="50px"
        >
          {newUser && (
            <Grid2 item>
              <Typography variant="body2">
                New Users: {newUser.newUsersCount}
              </Typography>
            </Grid2>
          )}
          {leftUser && (
            <Grid2 item>
              <Typography variant="body2">
                Left Users: {leftUser.leftUsersCount}
              </Typography>
            </Grid2>
          )}
          {userConversion && (
            <Grid2 item>
              <Typography variant="body2">
                Conversion Rate: {userConversion.conversionRate}
              </Typography>
            </Grid2>
          )}
          {userConversion && (
            <Grid2 item gap={2} flexDirection="column">
              <Typography variant="body2">
                Starter: {userConversionByPlan.starter}
              </Typography>
              <Typography variant="body2">
                Standard: {userConversionByPlan.standard}
              </Typography>
              <Typography variant="body2">
                Pro: {userConversionByPlan.pro}
              </Typography>
            </Grid2>
          )}
        </Grid2>

        <Grid2
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
        >
          {gainUserByDay && (
            <Grid2 item>
              <Bar
                data={chartData(gainUserByDay.usersByDay)}
                options={usersByDayOptions}
              />
            </Grid2>
          )}
          {leftByDay && (
            <Grid2 item>
              <Bar
                data={chartData(leftByDay.leftByDay)}
                options={LeftByDayOptions}
              />
            </Grid2>
          )}
          {utilisationByDay && (
            <Grid2 item>
              <Bar
                data={chartData(utilisationByDay.utilisationsByDay)}
                options={utilisationByDayOptions}
              />
            </Grid2>
          )}
        </Grid2>
      </Box>
      <Footer />
    </>
  );
};

export default Dashboard;
