import React, { useState, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete, ExpandMore } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hostname from "../../hostname";

const CollapsibleContainer = () => {
  // Gestion de l'état pour le conteneur dépliable
  const [expanded, setExpanded] = useState(true);
  const [formData, setFormData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const redirectToLogin = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRefreshToken = useCallback(async () => {
    try {
      const response = await fetch(`${hostname}/api/v1/user/auth/refresh`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.ok) {
        const { accessToken } = await response.json();
        localStorage.setItem("accessToken", accessToken);
        return accessToken;
      } else {
        redirectToLogin();
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      redirectToLogin();
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        toast.error("Access token is missing");
        redirectToLogin();
        return;
      }

      let response = await fetch(
        `${hostname}/api/v1/user/information/getUser`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 401 || response.status === 403) {
        const newAccessToken = await handleRefreshToken();
        if (newAccessToken) {
          response = await fetch(
            `${hostname}/api/v1/user/information/getUser`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${newAccessToken}`,
              },
            }
          );
        } else {
          throw new Error("Failed to refresh token");
        }
      }

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      const data = await response.json();
      setFormData(data);
      toast.error(null);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch data. Please try again later.");
    }
  }, [handleRefreshToken]);

  const handleDeleteAccount = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      let response = await fetch(`${hostname}/api/v1/user/auth/delete-user`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 401 || response.status === 403) {
        const newAccessToken = await handleRefreshToken();
        if (newAccessToken) {
          response = await fetch(`${hostname}/api/v1/user/auth/delete-user`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          });
        } else {
          throw new Error("Failed to refresh token");
        }
      }

      if (response.ok) {
        toast.success("Account successfully deleted");
        redirectToLogin();
        return true;
      }

      if (!response.ok) {
        const data = await response.json();
        if (data.message) {
          toast.error(data.message);
        } else {
          toast.error("Error during delete account");
        }
        return false;
      } else {
        throw new Error("Error during delete account");
      }
    } catch (error) {
      console.log("Error during delete account", error);
      return false;
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    const success = await handleDeleteAccount();
    if (success) {
      setOpenDeleteDialog(false);
      window.location.href = "/";
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary>
          <Grid2 container justifyContent="center" alignItems="center">
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMore />
            </IconButton>
            <span>Profile</span>
          </Grid2>
        </AccordionSummary>

        <AccordionDetails>
          <Grid2 container spacing={2}>
            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                fullWidth
                label="Firstname"
                placeholder="firstname"
                variant="outlined"
                name="firstname"
                value={formData.firstname || ""}
                disabled
              />
            </Grid2>

            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                fullWidth
                label="Lastname"
                placeholder="lastname"
                variant="outlined"
                name="lastname"
                value={formData.lastname || ""}
                disabled
              />
            </Grid2>

            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                fullWidth
                label="Mail"
                name="mail"
                placeholder="mail"
                variant="outlined"
                value={formData.mail || ""}
                disabled
              />
            </Grid2>

            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                fullWidth
                label="Phone number"
                placeholder="Phone number"
                variant="outlined"
                name="phone"
                value={formData.phone || ""}
                disabled
              />
            </Grid2>
          </Grid2>
          <Grid2 container justifyContent="center">
            <Button
              onClick={handleDeleteClick}
              aria-label="Delete Account"
              type="submit"
              maxWidth="200px"
              variant="contained"
              startIcon={<Delete />}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#5271ff",
                "&:hover": { backgroundColor: "#004aad" },
              }}
            >
              Delete Your Account
            </Button>
          </Grid2>
        </AccordionDetails>
      </Accordion>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete your account?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            color="primary"
            sx={{
              color: "white",
              backgroundColor: "#5271ff",
              "&:hover": { backgroundColor: "#004aad" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="secondary"
            sx={{
              color: "white",
              backgroundColor: "#FF0000",
              "&:hover": { backgroundColor: "#FF0000" },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollapsibleContainer;
