import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import {
  Grid2,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import Time from "../../../images/time.svg";
import Upload from "../../../images/upload_resume.svg";
import Icon from "../../../images/icon.png";
import World from "../../../images/world.jpg";
import SaveTime from "../../../images/time.jpg";
import Resume from "../../../images/resume.jpg";
import Logo from "../../../images/icon.png";
const landingPage = () => {
  return (
    <div>
      <Navbar />
      <Grid2
        container
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
        bgcolor="#f8f9fc"
        flexDirection="column"
      >
        <Grid2 textAlign="center" alignItems="center" marginBottom="20px">
          <Typography variant="body2" fontWeight="700" fontSize="30px">
            SmartCandidate
          </Typography>
        </Grid2>

        <Grid2
          container
          justifyContent="space-evenly"
          flexDirection="row"
          wrap="wrap"
          gap={2}
          sx={{ width: "100%" }}
        >
          <Grid2
            xs={12}
            sm={9}
            sx={{
              width: {
                xs: "100%",
                sm: "80%",
                lg: "60%",
              },
            }}
          >
            {" "}
            <iframe
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/Bf396oJktWY"
              title="SmartCandidate"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                maxWidth: "1000px",
                borderRadius: "8px",
              }}
            ></iframe>
          </Grid2>
          <Grid2 container xs={12} sm={3} width="270px" justifyContent="center">
            <img src={`${Logo}`} height="200px" width="250px" alt="Success!" />
            <Typography
              color="#5271ff"
              fontWeight="700"
              textAlign="center"
              paddingTop="20px"
            >
              Stand out with cover letters crafted to highlight your skills and
              experience, designed to meet recruiter expectations seamlessly.
            </Typography>
            <Button
              type="submit"
              fullWidth
              href="/signup"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              Signup
            </Button>
            <Button
              fullWidth
              href="https://chromewebstore.google.com/detail/smartcandidate/jgccmimjdjmhacdmjilocpikbacfbhlc"
              target="_blank"
              variant="contained"
              sx={{
                mt: 1,
                mb: 2,
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              Install
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        container
        bgcolor="#f8f9fc"
        flexDirection="column"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
      >
        <Grid2 marginBottom="20px">
          <Typography variant="h3" fontWeight="700" fontSize="30px">
            Services
          </Typography>
        </Grid2>
        <Grid2 container flexDirection="row" gap={3} padding="30px">
          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardMedia component="img" height="180" image={`${Resume}`} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Personalized Cover Letters
              </Typography>
              <Typography variant="body2" color="inherit">
                SmartCandidate crafts tailored cover letters that align
                perfectly with each job description. By analyzing your
                experiences and matching them to the position, our tool
                optimizes your application for maximum impact, helping you stand
                out to recruiters.
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardMedia component="img" height="180" image={`${SaveTime}`} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Quick and Easy to Use
              </Typography>
              <Typography variant="body2" color="inherit">
                In just a few clicks, SmartCandidate generates high-quality
                application documents without the hassle. Our intuitive design
                and simple interface let you create polished cover letters in
                seconds, saving you time and effort in your job search.
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              maxWidth: 325,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardMedia component="img" height="180" image={`${World}`} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Multilingual Support
              </Typography>
              <Typography variant="body2" color="inherit">
                SmartCandidate is available in multiple languages, allowing you
                to create professional documents suited to the region and
                language of the job. Whether you’re applying locally or
                internationally, your cover letter will meet the specific
                expectations of each job market.
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Grid2 container bgcolor="#f8f9fc" flexDirection="column" flexWrap="wrap">
        <Grid2 marginBottom="20px" marginTop="20px">
          <Typography
            variant="h3"
            fontWeight="700"
            fontSize="30px"
            textAlign="center"
          >
            Why SmartCandidate ?
          </Typography>
        </Grid2>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          padding="20px"
          justifyContent="space-evenly"
        >
          <Grid2 xs={6} marginBottom="20px">
            <Typography sx={{ marginTop: "20px" }} variant="h3" fontSize="30px">
              Save Time, Increase Opportunities
            </Typography>
            <Typography variant="body1" maxWidth="500px">
              Applying for jobs can be time-consuming, but SmartCandidate
              streamlines the process with quick and easy document generation.
              In just a few steps, you’ll have customized application materials
              ready to go, increasing the number of opportunities you can
              pursue. Make a lasting impression on recruiters without the extra
              effort.
            </Typography>
          </Grid2>
          <Grid2 xs={6} maxWidth="300px">
            <img src={`${Time}`} height="200px" width="auto" alt="Success!" />
          </Grid2>
        </Grid2>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          padding="20px"
          justifyContent="space-evenly"
        >
          <Grid2 xs={6} maxWidth="300px" marginBottom="20px">
            <img src={`${Upload}`} height="200px" width="auto" alt="Success!" />
          </Grid2>
          <Grid2 xs={6}>
            <Typography sx={{ marginTop: "20px" }} variant="h3" fontSize="30px">
              Effortless Personalization for Every Application
            </Typography>
            <Typography variant="body1" maxWidth="500px">
              SmartCandidate takes the stress out of creating tailored job
              applications. By aligning your experiences and skills with the
              specific requirements of each position, it crafts professional
              cover letters that are impactful and relevant. Whether you’re
              switching fields or advancing in your career, SmartCandidate helps
              you present the best version of yourself.
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 container bgcolor="#f8f9fc" flexDirection="column" flexWrap="wrap">
        <Grid2 marginBottom="20px" marginTop="20px">
          <Typography
            variant="h3"
            fontWeight="700"
            fontSize="30px"
            textAlign="center"
          >
            Contact Us
          </Typography>
        </Grid2>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="column"
          alignItems="center"
          flexWrap="wrap"
          padding="20px"
          justifyContent="center"
        >
          <Grid2 xs={6} maxWidth="300px">
            <img src={`${Icon}`} height="200px" width="auto" alt="Success!" />
          </Grid2>
          <Grid2 xs={6} marginBottom="20px">
            <Typography
              sx={{ marginTop: "20px" }}
              variant="h3"
              fontSize="30px"
              textAlign="center"
            >
              SmartCandidate
            </Typography>
            <Typography variant="body1" maxWidth="500px" textAlign="center">
              Unlock your career potential with SmartCandidate! Join us today to
              create customized, professional cover letters that set you apart.
              With tailored applications, you’re one step closer to landing your
              ideal job.
            </Typography>
            <Button
              type="submit"
              fullWidth
              href="/contact-us"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: "700",
                color: "white",
                backgroundColor: "#5271ff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#004aad",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              Contact
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  );
};

export default landingPage;
