import { React, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  Container,
  Grid2,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import hostname from "../../../hostname";

const Mail_validation = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    code: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${hostname}/api/v1/user/auth/activate-account`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          toast.success(data.message);
        }
        navigate("phone-validation");
      } else {
        const data = await response.json();
        if (data.message) {
          toast.error(data.message);
        } else {
          toast("Erreur d'authentification");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Grid2 container sx={{ minHeight: "100vh" }}>
        <Container
          container
          maxWidth="sm"
          sx={{
            paddingTop: 5,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 5,
              borderRadius: "15px",
            }}
          >
            <Avatar sx={{ m: 1, backgroundColor: "#5271ff" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Mail - Validation
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid2>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 13,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#5271ff",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#5271ff",
                    },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  name="code"
                  placeholder="1234"
                  autoFocus
                  value={formData.code}
                  onChange={handleChange}
                />
              </Grid2>
              <Box
                justifyContent="space-evenly"
                display="flex"
                marginTop="30px"
              >
                <Typography>
                  Verify your email address! We have sent a code to your inbox.
                  Enter this code to enjoy the benefits of your SmartCandidate
                  account!
                </Typography>
              </Box>
              <div>
                <ToastContainer theme="colored" />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Submit
              </Button>
              <Grid2 item>
                <Link href="/resend-mail-validation" variant="body2">
                  {"Resend the code"}
                </Link>
              </Grid2>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Footer />
    </div>
  );
};

export default Mail_validation;
