import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Download, CloudUpload } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hostname from "../../hostname";

const CVprofile = () => {
  const [cvUrl, setCvUrl] = useState(null);

  const redirectToLogin = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  const handleRefreshToken = useCallback(async () => {
    try {
      const response = await fetch(
        `http://localhost:3500/api/v1/user/auth/refresh`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      if (response.ok) {
        const { accessToken } = await response.json();
        localStorage.setItem("accessToken", accessToken);
        return accessToken;
      } else {
        redirectToLogin();
      }
    } catch (error) {
      console.error("Error during login :", error);
      redirectToLogin();
    }
  }, []);

  const fetchCV = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        redirectToLogin();
        return;
      }
      let response = await fetch(`${hostname}/api/v1/user/cv/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 401 || response.status === 403) {
        const newAccessToken = await handleRefreshToken();
        if (newAccessToken) {
          response = await fetch(`${hostname}/api/v1/user/cv/get`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${newAccessToken}`,
            },
          });
        } else {
          throw new Error("Failed to refresh token");
        }
      }

      if (!response.ok) {
        const data = await response.json();
        console.log(data.message);
      }

      const cvUrl = await response.json();
      setCvUrl(cvUrl);
    } catch (error) {
      console.error("Error during getting CV :", error);
    }
  }, [handleRefreshToken]);

  const uploadCV = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("cv", file);

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        redirectToLogin();
        return;
      }
      let response = await fetch(
        `${hostname}/api/v1/user/cv/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (response.status === 401 || response.status === 403) {
        const newAccessToken = await handleRefreshToken();
        if (newAccessToken) {
          response = await fetch(
            `${hostname}/api/v1/user/cv/upload`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${newAccessToken}`,
              },
              body: formData,
            }
          );
        } else {
          throw new Error("Failed to refresh token");
        }
      }

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      const data = await response.json();
      setCvUrl(data.cv.CvUrl);
      toast.success(data.message);
    } catch (error) {
      console.error("Error during upload CV :", error);
      toast.error("Error during upload CV.");
    }
  };

  useEffect(() => {
    fetchCV();
  }, [fetchCV]);
  return (
    <div>
      {" "}
      <Box
        sx={{
          width: 800,
          p: 3,
          borderRadius: 1,
          boxShadow: 1,
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Your CV
        </Typography>

        {cvUrl ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: "#5271ff",
              "&:hover": { backgroundColor: "#004aad" },
            }}
            startIcon={<Download />}
            href={cvUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            See your CV
          </Button>
        ) : (
          <Button variant="contained" color="primary" fullWidth disabled>
            No CV Available
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            backgroundColor: "#5271ff",
            "&:hover": { backgroundColor: "#004aad" },
          }}
          component="label"
          startIcon={<CloudUpload />}
        >
          Update your CV
          <input type="file" hidden onChange={uploadCV} />
        </Button>
        <ToastContainer theme="colored" />
      </Box>{" "}
    </div>
  );
};

export default CVprofile;
