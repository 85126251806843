import { React, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  Container,
  Grid2,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import hostname from "../../../hostname";
import { Flag, Phone } from "@mui/icons-material";

const ResendPhoneValidation = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    countryCode : "",
    phone: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${hostname}/api/v1/user/auth/resend-phone-verification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          toast.success(data.message);
        }
        navigate("/signup/mail-validation/phone-validation");
      } else {
        const data = await response.json();
        if (data.message) {
          toast.error(data.message);
        } else {
          toast("Erreur d'authentification");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Grid2 container sx={{ minHeight: "100vh" }}>
        <Container
          maxWidth="sm"
          sx={{
            paddingTop: 5,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 5,
              borderRadius: "15px",
            }}
          >
            <Avatar sx={{ m: 1, backgroundColor: "#5271ff" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Resend Phone Validation
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid2 container spacing={1}>
                <Grid2 item size={{ xs: 12, sm: 3 }}>
                  {" "}
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 13,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#5271ff",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#5271ff",
                      },
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="countryCode"
                    label="countryCode"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    autoComplete="countryCode"
                    autoFocus
                    placeholder="+33"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Flag />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 9 }}>
                  {" "}
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 13,
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#5271ff",
                        },
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#5271ff",
                      },
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    autoComplete="phone"
                    autoFocus
                    placeholder="612345678"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Phone />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid2>
              </Grid2>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Footer />
      <ToastContainer theme="colored" />
    </div>
  );
};

export default ResendPhoneValidation;
