import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import logo from "../../images/icon.png";
import { Button } from "@mui/material";
import { Home, Logout, MenuOpen } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import hostname from "../../hostname";
import { useNavigate } from "react-router-dom";

const drawerWidth = 300;

export default function PrimarySearchAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const LogoutFunction = async (event) => {
    event.preventDefault();
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${hostname}/api/v1/user/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "include",
      });

      if (response.ok) {
        try {
          const data = await response.json();
          console.log("Successfully Logout:", data.message);
        } catch (e) {
          console.warn("Error during logout");
        }
        localStorage.removeItem("accessToken");
        navigate("/");
      } else {
        console.error("Error during logout:", response.statusText);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "left" }}>
      <Typography
        variant="h6"
        sx={{ padding: 2, color: "#922B21", fontWeight: "bold" }}
      >
        <a href="/">
          <img height="90px" src={`${logo}`} alt="logo" />
        </a>
      </Typography>
      <Divider />
      <List>
        <a href="/login">
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "left", color: "black" }}>
              <ListItemText sx={{ color: "black" }} primary="Connexion" />
            </ListItemButton>
          </ListItem>
        </a>

        <a href="/user">
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText sx={{ color: "black" }} primary="User" />
            </ListItemButton>
          </ListItem>
        </a>

        <a href="/about-us">
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText sx={{ color: "black" }} primary="A propos" />
            </ListItemButton>
          </ListItem>
        </a>

        <a href="/contact-us">
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText sx={{ color: "black" }} primary="Contact" />
            </ListItemButton>
          </ListItem>
        </a>

        <a href="/logout">
          <ListItem disablePadding>
            <ListItemButton onClick={LogoutFunction} sx={{ textAlign: "left" }}>
              <ListItemText sx={{ color: "black" }} primary="Logout" />
            </ListItemButton>
          </ListItem>
        </a>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar position="sticky">
        <Toolbar
          style={{
            background: "#5271ff",
            height: "90px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            onClick={handleDrawerToggle}
            aria-label="open drawer"
            sx={{ mr: 2, display: { xs: "flex", md: "none" } }}
          >
            <MenuOpen color="white" />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <a href="/">
              <img height="90px" src={`${logo}`} alt="logo" />
            </a>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              href="/user"
              sx={{ color: "white", fontWeight: "700" }}
            >
              <Tooltip title="User space" arrow>
                <Home />
              </Tooltip>
            </IconButton>
            <Button href="/about-us" sx={{ color: "white", fontWeight: "700" }}>
              About us
            </Button>
            <Button
              href="/contact-us"
              sx={{ color: "white", fontWeight: "700" }}
            >
              Contact
            </Button>
            <IconButton
              onClick={LogoutFunction}
              sx={{ color: "white", fontWeight: "700" }}
            >
              <Tooltip title="Logout" arrow>
                <Logout />
              </Tooltip>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
